import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { Paragraph } from "./Paragraph";

type Choice = {
    content: string[],
    path: string
}

type ChoicesProps = {
    choices: Choice[],
    navigate: (str : string) => void,
    enabled?: boolean
}

export const Choices: React.FC<ChoicesProps> = (props: ChoicesProps) => {
    return (
        <VStack spacing="50" shouldWrapChildren alignItems={'center'}>
            {props.choices.map((choice: Choice) =>
                <VStack spacing={0}>
                    <Box bg="black" h='0.1' w='100%'/>
                    <Box as='button' minWidth='600' padding='3'
                        onClick={() => props.navigate('/novel/' + choice.path)}
                        alignItems='left'
                    >
                        <Paragraph tag="strong" paragraph={choice.content} />
                    </Box>
                    <Box bg="black" h='0.1' w='100%'/>
                </VStack>
            )}
        </VStack>
    )
}